import React from "react";
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import Lottie from "lottie-react";
import blobAnimation from "../images/lf20_8RV8Pi.json";
import HlavniText  from "../texty/main.mdx"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <title>O MNĚ | Blanka Opekarová</title>
        <meta name="description" content="O MNĚ | Blanka Opekarová" />
      </Helmet>

      <div className="flex mb-4 flex-wrap">
     <div  className="w-full md:w-1/2 ">
        <HlavniText/>
      </div>

        <div className="block w-full md:w-1/2   ">
        <Lottie animationData={blobAnimation} loop={true} />
        </div>
      </div>

     </Layout>
  )
}
