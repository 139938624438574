import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Blanka Opekarová`}</h1>
    <p>{`Spisovatel Hervé Tullet řekl: "Blop je jednoduchý tvar, někde mezi květinou a motýlem, nebo mezi flekem
a kresbou dítěte. Především ale, blop je to, co chcete, aby to bylo."   `}</p>
    <h1></h1>
    <p>{`A tak i ode mne můžete chtít spoustu věcí.
Třeba napsat texty na web, které řeknou přesně to, co chcete a tak, jak chcete.
Vytvořit newsletter, který doplní vaši značku. Udělat strategii interní komunikace.
Vymyslet kreativní řešení komunikace vašeho projektu. Najít přesně ten detail, který
dělá vaši značku jedinečnou. Možná potřebujete něco úplně jiného. Napíšete mi to?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      